import React, { useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  FloatingLabel,
  Dropdown,
  Table,
  Alert,
  FormSelect
} from 'react-bootstrap'


import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'

import clubLogo from '../../images/club_logo.svg'
import bentoLogo from '../../assets/images/logo_sao_bento.png'
import userTest from '../../assets/images/user/jeff_test.jpeg'
import netTest from '../../assets/images/user/neg.jpeg'
import Loader from '../../shared/loader'

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

const strongPassword = (password: any) => {
  return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
    password
  )
}

interface Props {
  onResponse: (response: any) => void
}

const SearchTicket = ({ onResponse }: Props) => {

  const auth = sessionStorage.getItem('auth')

  const currentGate = sessionStorage.getItem('gate')

  if (auth !== 'true') {
    window.location.href = '/'
  }

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const [startDate, setStartDate] = useState(new Date())

  const [hasToken, setHasToken] = useState('')

  const [disableSign, setDisableSign] = useState(false)

  const [userNotFound, setUserNotFound] = useState(false)

  const [motivation, setMotivation] = useState({
    show: false,
    case: 0
  })

  const [loading, setLoading] = useState(false)

  const [userData, setUserData] = useState({
    image: '---',
    name: '',
    documentType: '',
    document: '',
    birthday: '',
    transaction: '',
    game: '',
    gameTime: '',
    sector: '',
    gate: '',
    block: '',
    row: '',
    sit: '',
    ticket: '',
    ticketStatus: '',
    ticketUsed: {
      date: '',
      time: '',
      gate: ''
    },
    ingPortao: '',
    supervisor: false
  })

  const [searchUser, setSearchUser] = useState('')

  const [newUser, setNewUser] = useState({
    login: '',
    password: '',
    set: false
  })

  // const [currentGate, setCurrentGate] = useState('')

  const onSubmit = (data: any) => {

    sessionStorage.removeItem('ticket')
    sessionStorage.removeItem('usedTicket')

    setUserNotFound(false)
    setSearchUser('')
    setLoading(true)

    // if(data.document === '888'){
    //   setUserNotFound(true)
    // }else{
    //   setSearchUser(data.document)
    // }
    // setDisableSign(true)

    fetch(`${BASE.ticket_api.base_url}${BASE.ticket_api.user_validate}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        value: data.document,
        search: data.type,
      })
    })
      .then((res) => res.json())
      .then((response) => {

        setLoading(false)
        if (!response.document) {
          return setUserNotFound(true)
        }
        setUserNotFound(false)
        setSearchUser(response.document)
        sessionStorage.setItem('ticket', response.ticket)
        sessionStorage.setItem('gameCode', (response.gameCode || '1111'))
        if (response.ticketStatus === 'valid' || response.ticketStatus === 'discounted') {
          sessionStorage.setItem('usedTicket', 'true')
        }
        sessionStorage.setItem('ticketDocument', data.document)
        setUserData(response)

        if (userData.ingPortao !== currentGate) {
          sendWrongGate(response.ticket, currentGate)
        }
      })
      .catch((error) => {
        setUserNotFound(true)
        setLoading(false)
        console.log(error)
      })
  }

  const motivationSubmit = (data: any) => {
    sessionStorage.setItem('reason', data.case)
    onResponse(1)
  }

  const sendWrongGate = (wrongTicket: any, wrongGate: any) => {
    fetch(`${BASE.ticket_api.base_url}${BASE.ticket_api.generateAccess}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        "ticket": wrongTicket,
        "gate": wrongGate,
        "deviceIp": "0.0.0.0",
        "registeredFace": "wrongGate",
        'status': 'waiting'
      })
    })
      .catch((error) => {
        console.log(error)
      })
  }
  const resetForm = () => {
    setNewUser({
      login: '',
      password: '',
      set: false
    })
    setHasToken('')
    setDisableSign(false)
    reset()
  }

  if (loading) {
    return (<Loader />)
  }

  // 40418424845
  // setMotivation
  if (motivation.show) {
    return (
      <Form onSubmit={handleSubmit(motivationSubmit)}>
        <Table responsive striped >
          <tbody>
            <tr>
              <td>Foto</td>
            </tr>
            <tr>
              <td><img src={`data:image/png;base64,${userData.image}`} /></td>
            </tr>
            <tr>
              <td>Qual o motivo da contingência?</td>
            </tr>
            <tr>
              <td className='reason-radios'>

                <Form.Check
                  {...register('case', { required: true })}
                  label="⁠Leitor não reconheceu a face do torcedor"
                  name="case"
                  type="radio"
                  value="1"
                  id={`1`}
                />
                <Form.Check
                  {...register('case', { required: true })}
                  label="Foto de outra pessoa"
                  name="case"
                  type="radio"
                  value="2"
                  id={`2`}
                />
                {errors.case && (
                  <span className="label-error">
                    Você precisa escolher uma causa
                  </span>
                )}

              </td>
            </tr>
            <tr>
              <td>
                <Button
                  disabled={disableSign}
                  type="submit"
                  className="mb-0 w-100"
                >
                  Prosseguir
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
    )
  }
  
  const changeGate = (gate:any) => {
    sessionStorage.setItem('gate', gate)
    return window.location.reload()
  }

  const enumGate = (gateNumber:any) =>{

    switch (gateNumber) {
      case '1':
        return 'A Central Oeste';
      case '2':
          return 'A Superior Sul';
      case '3':
          return 'A Gol Sul';
      case '6':
          return 'B Superior Leste';
      case '7':
          return 'B Gol Norte';
      case '8':
          return 'C Central Leste';
      case '5':
          return 'D Visitantes';
      case '16':
        return 'B Telão';
      default:
        return 'N/A';
    }
  }

  return (
    <>
      {/* <Button
        disabled={disableSign}
        type="submit"
        className="mb-0 w-100"
      >
        Portão Atual: A
      </Button> */}

      <select className="form-control btn btn-default mb-0 w-100"
          onChange={(event) => {
            const { value } = event.target
            changeGate(value)
          }}>
          <option>Portão Atual {enumGate(currentGate)}</option>
          <option value="1">Portão: A Central Oeste</option>
          <option value="2">Portão: A Superior Sul</option>
          <option value="3">Portão: A Gol Sul</option>
          <option value="6">Portão: B Superior Leste</option>
          <option value="7">Portão: B Gol Norte</option>
          <option value="16">Portão: B Telão</option>
          <option value="8">Portão: C Central Leste</option>
          <option value="5">Portão: D Visitantes</option>
      </select>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">
                <span className='club-header'>
                  Palmeiras
                  <img src={clubLogo} />
                </span>

                <span className='club-header'>
                  {/* <img src={bentoLogo} />
            São Bento */}
                  Controle de acesso
                </span>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="mb-3">
                      <Col>
                        <Form.Label>Tipo do documento</Form.Label>
                        <FormSelect
                          className="form-control"

                          onChange={(event) => {
                            const { value } = event.target
                            // setReportType(value)
                          }}
                        >
                          <option value="access">CPF</option>
                          <option value="signup">Outro</option>
                        </FormSelect>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Label>Número do documento</Form.Label>
                        <Form.Control placeholder="Número do documento" {...register('document')} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Button
                            disabled={disableSign}
                            type="submit"
                            className="mb-0 w-100"
                          >
                            Pesquisar
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                    {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                  </Form>
                </Col>
              </Row>

              {newUser.set && (
                <>
                  <hr />
                  <Row>
                    <Col className="qr-wrapper">
                      <h3>Aprovador cadastrado com sucesso!</h3>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card></Col>
      </Row>
      {userNotFound && <Row>

        <Col>
          <Alert className='text-center' variant='danger'>Não encontrado</Alert>
        </Col>
      </Row>
      }
      {searchUser && <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">
                <span className='club-header'>Dados do Ingresso</span>
              </Card.Title>
            </Card.Header>
            {(userData.ingPortao !== currentGate && userData.supervisor === false) &&
              <Button
                disabled={true}
                type="submit"
                className={`ticket-action red`}
              >
                PORTÃO ERRADO
              </Button>
            }
            {userData.supervisor === true &&
              <Button
                disabled={true}
                type="submit"
                className={`ticket-action purple`}
              >
                CONTACTAR SUPERVISOR
              </Button>
            }

            {(userData.ingPortao === currentGate && userData.supervisor === false) &&
              <Button
                disabled={disableSign}
                type="submit"
                className={`ticket-action`}

                onClick={() => {

                  // if(confirm("Tirar ?")){
                  // onResponse(1)
                  setMotivation({ show: true, case: 0 })
                  // }
                }}
              >
               Gerar Acesso
              </Button>
            }

            <Table responsive striped >
              <tbody>
                <tr>
                  <td>Foto</td>
                </tr>
                <tr>

                  <td><img src={`data:image/png;base64,${userData.image}`} /></td>
                </tr>

                <tr>
                  <td>Nome</td>
                </tr>
                <tr>
                  <td>{userData.name}</td>
                </tr>

                <tr>
                  <td>Documento ({userData.documentType})</td>
                </tr>
                <tr>
                  <td>{userData.document}</td>
                </tr>

                <tr>
                  <td>Data de Nascimento</td>
                </tr>
                <tr>
                  <td>{userData.birthday}</td>
                </tr>

                <tr>
                  <td>Transação</td>
                </tr>
                <tr>
                  <td>#{userData.transaction}</td>
                </tr>
                <tr>
                  <td>Partida:</td>
                </tr>
                <tr>
                  <td>{userData.game}</td>
                </tr>

                <tr>
                  <td>Data e hora da partida</td>
                </tr>
                <tr>
                  <td>{userData.gameTime}</td>
                </tr>

                <tr>
                  <td>Setor: {userData.sector} Portão: {userData.gate}</td>
                </tr>
                <tr>
                  <td>Bloco: {userData.block} Fileira {userData.row} Assento: {userData.sit}</td>
                </tr>

                <tr>
                  <td>Número do ingresso</td>
                </tr>
                <tr>
                  <td>{userData.ticket}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                </tr>
              </tfoot>
            </Table>
            {(userData.ingPortao === currentGate && userData.supervisor === false) &&
              <Button
                disabled={disableSign}
                type="submit"
                className={`ticket-action`}

                onClick={() => {

                  // if(confirm("Tirar ?")){
                    setMotivation({ show: true, case: 0 })
                  // }
                }}
              >
                Gerar Acesso
              </Button>
            }

            {userData.ticketStatus === 'invalid' &&
              <Table responsive striped >
                <tbody>
                  <tr className='text-center'>
                    <td>Utilização</td>
                  </tr>
                  <tr>
                    <td className='text-center'>
                      {userData.ticketUsed.date} - {userData.ticketUsed.time} <br />
                      Portão {userData.ticketUsed.gate}
                    </td>
                  </tr>
                </tbody></Table>}
            <Card.Body>

              <Button variant="outline-primary" className='mb-0 w-100 ' onClick={() => setSearchUser('')}>Pesquisar Outro</Button>

            </Card.Body>

          </Card></Col>
      </Row>}

    </>

  )
}

export default SearchTicket
